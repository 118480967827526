/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input {
    @apply text-sm focus:outline-none w-full py-2 px-3 border border-gray-300 rounded-md
    disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200;
  }
}

.list-num ol {
  list-style-position: inside;
  list-style-type: decimal;
}

.modal-enter > .modal-block {
  opacity: 0;
  transform: scale(1.1);
}

.modal-enter-active > .modal-block {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.modal-exit > .modal-block {
  opacity: 1;
  transform: scale(1);
}

.modal-exit-active > .modal-block {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.modal-enter > .modal-bg {
  opacity: 0;
}

.modal-enter-active > .modal-bg{
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}

.modal-exit > .modal-bg {
  opacity: 1;
}

.modal-body::-webkit-scrollbar {
  display: none;
}

.modal-exit-active > .modal-bg {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

/* width */
.modal-block::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.modal-block::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.modal-block::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
  border-radius: 10px;
}